

import { createRouter, createWebHashHistory,createWebHistory, createRouterMatcher } from 'vue-router'

// redirect: 'tv'
  export const constantRoutes = [
    {
      path: '/',
      name: 'home',
      redirect: 'home'
    },
    {
      path: '/home',
      component: () => import('@/pages/datav_ehs')
    },
    {
      path: '/tv',
      component: () => import('@/pages/datav_tv')
    },
    {
      path: '/report',
      component: () => import('@/pages/datav_report')
    },
    {
        path: '/ehs',
        component: () => import('@/pages/datav_ehs')
    },
    {
        path: '/wms',
        component: () => import('@/pages/datav_wms')
    },
    // {
    //   path: '*',
    //   name: '/404',
    //   redirect: '404'
    // },
    {
      path: "/:catchAll(.*)",
      redirect: "/404"
    },
    {
      path: '/:pathMatch(.*)',
      redirect: '404'
    },
    {
      path: '/404',
      component: () => import('@/pages/error/404'),
    //   hidden: true
    }
  ]
   
  
// const routers = constantRoutes.concat(newRoutes);

// const router = new VueRouter({
//     // base: routers,
//     // mode: routerMode,
//     // mode: 'history', // require service support
//     scrollBehavior: () => ({
//       y: 0,
//     }),
//     routes: constantRoutes,
//   })
  
//   export function resetRouter() {
//     location.reload()
//   }


const routers = constantRoutes
// 创建新的路由
export function createNewRouter(){
  return createRouter({
    history: createWebHashHistory(), //createWebHistory(),
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: routers// constantRouterMap// routers// constantRoutes
  })
}
const router = createNewRouter()
// 定义一个resetRouter 方法，在退出登录后或token过期后 需要重新登录时，调用即可
export function resetRouter () {
  const newRouter = createNewRouter()//router
  router.matcher = newRouter.matcher
}

  
  export default router
  