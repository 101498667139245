// import Vue from 'vue'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import './assets/common.less'
// import 'swiper/swiper.css'


import Antd from "ant-design-vue"
// import {MinusOutlined, PlusOutlined, SearchOutlined} from '@ant-design/icons-vue';
// import "@ant-design-vue/pro-layout/dist/style.css";
// import 'ant-design-vue/dist/antd.css'
import 'ant-design-vue/dist/reset.css';

// import { BorderBox1, BorderBox10, BorderBox11, BorderBox12, BorderBox13, BorderBox2, BorderBox3, BorderBox4, BorderBox5, BorderBox6, BorderBox7, BorderBox8, BorderBox9, Decoration1, Decoration2, Decoration3, Decoration4, Decoration5, Decoration6, Decoration7, Decoration8, Loading, setClassPrefix } from '@dataview/datav-vue3';
// import dataV from '@dataview/datav-vue3';
// import dataV from '@jiaminghi/data-view'
// Vue.config.productionTip = false

// Vue.use(dataV)

import vue3SeamlessScroll from "vue3-seamless-scroll";


// import VueAwesomeSwiper from 'vue-awesome-swiper'
const app = createApp(App)


// app.component(Decoration1, 'dv-decoration-1')
// app.component(Decoration2, 'dv-decoration-2')
// app.component(Decoration8, 'dv-decoration-8')
// app.component(Decoration5, 'dv-decoration-5')


// app.use(DataV, { classNamePrefix: 'dv-' });
// setClassNamePrefix('dv-')
app.use(vue3SeamlessScroll)
app.use(Antd)
app.use(router).mount('#app')